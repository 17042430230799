







































































import Vue from 'vue'
import {
  mapActions,
  mapMutations,
  mapState
} from 'vuex'
import Loading from '@/components/Loading/index.vue'
import ModalAddImage from '@/components/ModalAddImage.vue'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import ImageUploader from 'vue-image-upload-resize'
import Add from './Add.vue'

export default Vue.extend({
  name: 'ProfilePosts',

  components: {
    Loading,
    Add,
    ModalAddImage,
    CoolLightBox,
    ImageUploader
  },

  data () {
    return {
      modalShow: false,
      offset: 0,
      limit: 25,
      loading: false,
      newPostImage: null,
      galleryIndex: null
    }
  },

  watch: {
    $route (val) {
      this.offset = Number(this.$route.query.offset || 0)
      this.getPostsPage()
    }
  },

  computed: {
    ...mapState('posts', [
      'posts'
    ]),

    postsImages () {
      return this.posts.map(post => {
        return post.media[0].payload.main_image_url
      })
    }
  },

  async mounted () {
    this.offset = Number(this.$route.query.offset || 0)
    this.getPostsPage()
  },

  beforeDestroy () {
    this.resetPosts()
  },

  methods: {
    ...mapActions('posts', [
      'getPosts',
      'deletePost',
      'addPost'
    ]),

    ...mapActions('common', [
      'uploadMedia'
    ]),

    ...mapMutations('posts', [
      'resetPosts'
    ]),

    async onImageUploaded (url: string) {
      try {
        this.loading = true
        await this.addPost({
          user_id: this.$route.params.profileId,
          media: [
            {
              payload: {
                source: 'gallery',
                main_image_url: url,
                blur_image_url: url
              }
            }
          ]
        })
        setTimeout(() => {
          this.getPostsPage()
        }, 500)
        this.modalShow = false
        setTimeout(() => {
          this.$toast.success('Пост опубликован')
        }, 100)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.modalShow = false
        this.loading = false
      }
    },

    goPrevPage () {
      this.offset -= 25
      if (this.offset < 0) this.offset = 0
      this.$router.push({ name: this.$route.name, query: { offset: this.offset } })
    },

    goNextPage () {
      this.offset += 25
      this.$router.push({ name: this.$route.name, query: { offset: this.offset } })
    },

    async getPostsPage () {
      this.loading = true
      await this.getPosts({
        userId: this.$route.params.profileId,
        limit: this.limit,
        offset: this.offset
      })
      this.loading = false
    },

    showAddPostModal () {
      this.modalShow = true
    },

    confirmDeletePost (post) {
      const h = this.$createElement
      const titleVNode = h('div', { domProps: { innerHTML: 'Удалить эту публикацию?' } })
      const messageVNode = h('div', { class: ['preview-img'] }, [
        h('b-img', {
          props: {
            src: post.media[0].payload.main_image_url,
            center: true,
            fluid: true,
            rounded: true
          }
        })
      ])

      this.$bvModal.msgBoxConfirm(messageVNode, {
        title: titleVNode,
        size: 'sm',
        // okVariant: 'danger',
        okTitle: 'Удалить',
        cancelTitle: 'Отмена',
        footerClass: 'p-2 justify-content-center',
        // hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if (value) this.handleDeletePost(post.id)
        })
        .catch(err => {
          console.log(err)
        })
    },

    async handleDeletePost (postId: string) {
      try {
        this.loading = true
        await this.deletePost(postId)
        this.$toast.success('Пост успешно удалён')
        this.getPostsPage()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    }
  }
})
