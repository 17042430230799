





import Vue from 'vue'
// import {
//   mapActions,
//   mapState
// } from 'vuex'
import Loading from '@/components/Loading/index.vue'

export default Vue.extend({
  name: 'ProfileStatistics',

  components: {
    Loading
  },

  data () {
    return {
    }
  },

  computed: {
  },

  mounted () {
  },

  methods: {
  }
})
